<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1" *ngIf="!data.contract">Создать договор</div>
        <div class="body-text-1" *ngIf="data.contract">Редактировать договор</div>
        <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div *ngIf="displayCompanyControl" class="popup-body-row">
            <mat-form-field appearance="fill" color="primary" class="input-select-client"
                            [class.input-select__opened]="searchOpened">
                <mat-label>Клиент</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select searchSelect (openedChange)="searchOpened = $event" disableRipple
                            formControlName="company" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']"
                >
                    <mat-select-trigger>
                        {{form.value?.company?.name}} {{form.value?.company?.inn}} {{form.value?.company?.note}} {{form.value?.company?.contact}}
                    </mat-select-trigger>
                    <div appSimplebarScroller>
                        <div class="search-container">
                            <mat-form-field style="width: 100%;" class="input-field" color="primary">
                                <mat-label>Поиск</mat-label>
                                <input type="text" matInput id="searchCompany" formControlName="companySearchQuery">
                            </mat-form-field>
                        </div>
                        <mat-option *ngFor="let company of companies" [value]="company">
                            <div style="display: flex;">
                                <div style="padding-right: 16px;min-width: 130px;">{{company?.name}}</div>
                                <div style="padding-right: 16px;min-width: 130px;">{{company?.inn}}</div>
                                <div style="padding-right: 16px;min-width: 130px;">{{company?.note}}</div>
                                <div style="min-width: 130px;">{{company?.contact}}</div>
                            </div>
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Название договра*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="name">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <input-date-time-picker placeholder="Дата заключеня*" [disabled]="form.disabled" [date]="form.value.date" [showTime]="false"
                                    (change)="onDateChange($event)"></input-date-time-picker>
        </div>
        <div class="popup-body-row">
            <input-date-time-picker placeholder="Дата действия с" [disabled]="form.disabled" [date]="form.value.dateBegin" [showTime]="false"
                                    (change)="onDateBeginChange($event)"></input-date-time-picker>
        </div>
        <div class="popup-body-row">
            <input-date-time-picker placeholder="Дата действия по" [disabled]="form.disabled" [date]="form.value.dateEnd" [showTime]="false"
                                    (change)="onDateEndChange($event)"></input-date-time-picker>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Сумма</mat-label>
                <input type="number" autocomplete="off" matInput formControlName="amount">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Примечание</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="description">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Менеджер</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="manager">
            </mat-form-field>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer">
        <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled button-primary-filled-secondary"
            color="primary"
            [disabled]="form.invalid || form.disabled"
            (click)="save()"
        >
            Сохранить
        </button>
    </div>
</div>

