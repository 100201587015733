import {Component, OnInit} from '@angular/core';
import {
    EventSessionSettingsService,
    SettingsLoadingStatus,
    SettingsPopupState
} from '../event-session-settings.service';
import {AbstractComponent} from '../../../shared/abstract.component';
import {EventSessionClientLimit} from '../../../shared/types/event-session-client-limit';
import {ConfirmationPopupService} from '../../confirmation-popup/confirmation-popup.service';
import {FullscreenLoaderService} from '../../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';
import {DefaultErrorPopupService} from '../../default-error-popup/default-error-popup.service';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {
    CuEventSessionClientLimitPopupComponent, CuEventSessionClientLimitPopupResult
} from '../../cu-event-session-client-limit-popup/cu-event-session-client-limit-popup.component';
import {formatDate} from '../../../shared/utils/date-utils';
import {ClientGroup} from '../../../shared/types/client-group';

interface EventSessionClientLimitTable extends EventSessionClientLimit {
    dateBegin: string;
    timeBegin: string;
    dateEnd: string;
    timeEnd: string;
}

@Component({
    selector: 'app-client-limits',
    templateUrl: './client-limits.component.html',
    styleUrls: ['./client-limits.component.scss'],
})
export class ClientLimitsComponent extends AbstractComponent implements OnInit {
    public loading = true;

    public tableColumns: string[] = ['name', 'date', 'actions'];

    public dataSource: EventSessionClientLimitTable[] = [];

    private clientGroups: ClientGroup[] = [];

    constructor(
        private readonly eventSessionSettingsService: EventSessionSettingsService,
        private readonly confirmationPopupService: ConfirmationPopupService,
        private readonly fullscreenLoaderService: FullscreenLoaderService,
        private readonly defaultErrorPopupService: DefaultErrorPopupService,
        private readonly httpClient: HttpClient,
        private readonly matDialog: MatDialog,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.loading = true;

        this.eventSessionSettingsService.loading
            .pipe(
                filter(status => status === SettingsLoadingStatus.LOADED),
                switchMap(() => {
                    return forkJoin([
                        this.loadClientLimits(),
                        this.loadClientGroups()
                    ]);
                }),
                tap(() => {
                    this.loading = false;
                }),
                this.untilComponentDestroyed(),
            )
            .subscribe();
    }

    public createClientLimit(): void {
        this.matDialog.open(CuEventSessionClientLimitPopupComponent, {
            panelClass: ['primary-popup-panel', 'default-popup-panel'],
            backdropClass: 'primary-popup-backdrop',
            data: {
                eventSessionUuid: this.eventSessionUuid,
                clientGroups: this.clientGroups,
            },
            restoreFocus: false,
            disableClose: true,
            autoFocus: false
        }).afterClosed().subscribe(response => {
            if (response.result === CuEventSessionClientLimitPopupResult.SAVE) {
                this.upsertLimit(response.data);
            }
        });
    }

    public editClientLimit(clientLimit: EventSessionClientLimit): void {
        this.matDialog.open(CuEventSessionClientLimitPopupComponent, {
            panelClass: ['primary-popup-panel', 'default-popup-panel'],
            backdropClass: 'primary-popup-backdrop',
            data: {
                limit: clientLimit,
                eventSessionUuid: this.eventSessionUuid,
                clientGroups: this.clientGroups,
            },
            restoreFocus: false,
            disableClose: true,
            autoFocus: false
        }).afterClosed().subscribe(response => {
            if (response.result === CuEventSessionClientLimitPopupResult.SAVE) {
                this.upsertLimit(response.data);
            }
        });
    }

    public async removeClientLimit(clientLimit: EventSessionClientLimit): Promise<void> {
        const confirmed = await this.confirmationPopupService.confirm({
            title: 'Удалить группу',
            description: 'Вы действительно хотите удалить эту группу?'
        });
        if (!confirmed) {
            return;
        }
        this.fullscreenLoaderService.open();
        try {
            await this.httpClient.delete(`/adminpanelapi/event-session-client-limit/delete/${clientLimit.uuid}`).toPromise();
            this.dataSource = this.dataSource.filter(limit => limit.uuid !== clientLimit.uuid);
        } catch (e) {
            this.defaultErrorPopupService.open(e.error.message);
        }
        this.fullscreenLoaderService.close();
    }

    public next(): void {
        this.eventSessionSettingsService.setCurrentState(SettingsPopupState.ANNOUNCEMENT);
    }

    private get eventSessionUuid(): string {
        return this.eventSessionSettingsService.getEventSessionUuid() as string;
    }

    private loadClientLimits(): Observable<EventSessionClientLimit[]> {
        return this.httpClient.get<EventSessionClientLimit[]>(`/adminpanelapi/event-session-client-limit/all/by-event-session/${this.eventSessionUuid}`)
            .pipe(
                tap(limits => {
                    this.dataSource = limits.map(limit => this.convertLimit(limit));
                })
            );
    }

    private loadClientGroups(): Observable<ClientGroup[]> {
        return this.httpClient.get<ClientGroup[]>('/adminpanelapi/client-group/all')
            .pipe(
                tap(clientGroups => {
                    this.clientGroups = clientGroups;
                })
            );
    }

    private convertLimit(limit: EventSessionClientLimit): EventSessionClientLimitTable {
        return {
            ...limit,
            timeBegin: formatDate(limit.datetimeBegin, 'HH:mm'),
            dateBegin: formatDate(limit.datetimeBegin, 'EEEEEE, d MMM yyyy'),
            timeEnd: formatDate(limit.datetimeEnd, 'HH:mm'),
            dateEnd: formatDate(limit.datetimeEnd, 'EEEEEE, d MMM yyyy'),
        };
    }

    private upsertLimit(limit: EventSessionClientLimit): void {
        const converted = this.convertLimit(limit);

        const existing = this.dataSource.find(l => l.uuid === limit.uuid);

        if (existing) {
            existing.clientGroups = converted.clientGroups;
            existing.datetimeBegin = converted.datetimeBegin;
            existing.datetimeEnd = converted.datetimeEnd;
            existing.dateBegin = converted.dateBegin;
            existing.timeBegin = converted.timeBegin;
            existing.dateEnd = converted.dateEnd;
            existing.timeEnd = converted.timeEnd;
        } else {
            this.dataSource = this.dataSource.concat(converted);
        }
    }
}
