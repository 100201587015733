import {Component, Input} from '@angular/core';

@Component({
    selector: 'inline-alert-component',
    template: `
        <div class="inline-alert inline-alert-{{status}}">
            <mat-icon [svgIcon]="getIcon()"></mat-icon>
            <span class="body-text-2" [innerHTML]="text"></span>
        </div>
    `,
    styleUrls: ['./inline-alert.scss']
})
export class InlineAlertComponent {
    @Input()
    text: string = '';

    @Input()
    status: 'danger' | 'success' | 'warning' | 'info' = 'danger';

    public getIcon(): string {
        if (this.status === 'danger') {
            return 'error';
        }
        if (this.status === 'warning') {
            return 'warning-filled';
        }
        if (this.status === 'info') {
            return 'info';
        }
        return 'checkmark-filled';
    }
}
