import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {EventSessionClientLimit, EventSessionClientLimitCU} from '../../shared/types/event-session-client-limit';
import {formatNativeDate} from '../../shared/utils/date-utils';
import {ClientGroup} from '../../shared/types/client-group';

export enum CuEventSessionClientLimitPopupResult {
    CANCEL,
    SAVE,
}

@Component({
    templateUrl: './cu-event-session-client-limit-popup.component.html',
    styleUrls: ['./cu-event-session-client-limit-popup.component.scss'],
})
export class CuEventSessionClientLimitPopupComponent {
    public form: FormGroup;

    public popupResult = CuEventSessionClientLimitPopupResult;

    public error: string | null = null;

    constructor(
        private readonly dialogRef: MatDialogRef<CuEventSessionClientLimitPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            limit?: EventSessionClientLimit;
            eventSessionUuid: string;
            clientGroups: ClientGroup[];
        },
    ) {
        this.form = this.fb.group({
            start: [null, [Validators.required]],
            end: [null, [Validators.required]],
            clientGroupUuid: [[], [Validators.required]],
        });

        if (this.data.limit) {
            this.form.patchValue({
                start: new Date(this.data.limit.datetimeBegin),
                end: new Date(this.data.limit.datetimeEnd),
                clientGroupUuid: this.data.limit.clientGroups.map(clientGroup => clientGroup.uuid),
            });
        }
    }

    public onRangeChange({start, end}: { start: Date | null, end: Date | null }): void {
        this.form.patchValue({
            start, end
        });
    }

    public close(result: CuEventSessionClientLimitPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public save(): void {
        this.error = null;
        this.form.disable();

        const model: EventSessionClientLimitCU = {
            datetimeBegin: formatNativeDate(this.form.value.start, `yyyy-MM-dd'T'HH:mm:ss`),
            datetimeEnd: formatNativeDate(this.form.value.end, `yyyy-MM-dd'T'HH:mm:ss`),
            eventSessionUuid: this.data.eventSessionUuid,
            clientGroupUuid: this.form.value.clientGroupUuid,
        };

        if (this.data.limit) {
            model.uuid = this.data.limit.uuid;
        }

        const request$ = model.uuid ?
            this.httpClient.put<ClientGroup>('/adminpanelapi/event-session-client-limit/update', model)
            : this.httpClient.post<ClientGroup>('/adminpanelapi/event-session-client-limit/create', model);

        request$
            .pipe(
                tap(limit => {
                    this.close(CuEventSessionClientLimitPopupResult.SAVE, limit);
                }),
                catchError(error => {
                    this.error = error.error.message;
                    this.form.enable();
                    return of(error);
                }),
            )
            .subscribe();
    }
}
