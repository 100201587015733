import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent, HttpErrorResponse,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SnackBarService} from '../shared/ui-kit/snack-bar/snack-bar.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(
        private readonly snackBarService: SnackBarService,
        private readonly router: Router,
        private readonly matDialog: MatDialog
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event) => {
                },
                (err) => {
                    if (err instanceof HttpErrorResponse) {
                        switch (err.status) {
                            case -1:
                            case 0: {
                                this.snackBarService.show('Нет подключения к Интернету');
                                break;
                            }
                            case 500: {
                                this.snackBarService.show('Внутренняя ошибка сервера');
                                break;
                            }
                            case 502:
                            case 503: {
                                this.snackBarService.show('Сервер недоступен');
                                break;
                            }
                            case 401: {
                                if (!this.router.url.startsWith('auth')) {
                                    this.matDialog.closeAll();
                                    const currentUrl = this.router.url;
                                    window.location.href = `/auth?redirectUrl=${currentUrl}`;
                                    return of();
                                }
                                return of(err);
                            }
                        }
                    }
                    if (err.status === 400) {
                        if (err.error?.violations instanceof Array) {
                            err.error = {
                                message: err.error?.violations[0]?.message
                            };
                        } else {
                            err.error = {
                                message: err.error?.message
                            };
                        }
                    }
                    return of(err);
                }
            )
        );
    }
}
