<div class="page-container" *ngIf="!loading">
    <div class="sale-limits-container">
        <div class="sale-limits-table">
            <mat-table [dataSource]="table" appSimplebarScroller [scrollWithMouse]="true" [formGroup]="form">
                <ng-container matColumnDef="client">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Клиент" [state]="getSortDirection('customerName')"
                                  (onChange)="onSortChange('customerName', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3">{{element.customerName}}</div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="client-max-quantity">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Количество мест в учетной записи" [state]="getSortDirection('clientMaxQuant')"
                                  (onChange)="onSortChange('clientMaxQuant', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3"
                                 *ngIf="editingLimit?.uuid !== element.uuid">{{element.clientMaxQuant}}</div>
                            <mat-form-field class="input-field" color="primary"
                                            *ngIf="editingLimit?.uuid === element.uuid">
                                <mat-label>Кол-во мест</mat-label>
                                <input type="text" autocomplete="off" mask="099999999999" [patterns]="customPatterns"
                                       [validation]="false" [dropSpecialCharacters]="false" matInput
                                       formControlName="clientMaxQuant">
                            </mat-form-field>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="reserve-max-quantity">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Количество мест в заказе" [state]="getSortDirection('reserveMaxQuant')"
                                  (onChange)="onSortChange('reserveMaxQuant', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3"
                                 *ngIf="editingLimit?.uuid !== element.uuid">{{element.reserveMaxQuant}}</div>
                            <mat-form-field class="input-field" color="primary"
                                            *ngIf="editingLimit?.uuid === element.uuid">
                                <mat-label>Кол-во мест</mat-label>
                                <input type="text" autocomplete="off" mask="099999999999" [patterns]="customPatterns"
                                       [validation]="false" [dropSpecialCharacters]="false" matInput
                                       formControlName="reserveMaxQuantity">
                            </mat-form-field>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="reserve-minutes">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Время создания заказа, мин." [state]="getSortDirection('reserveMinutes')"
                                  (onChange)="onSortChange('reserveMinutes', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3"
                                 *ngIf="editingLimit?.uuid !== element.uuid">{{element.reserveMinutes}}</div>
                            <mat-form-field class="input-field" color="primary"
                                            *ngIf="editingLimit?.uuid === element.uuid">
                                <mat-label>Время создания</mat-label>
                                <input type="text" autocomplete="off" mask="099999999999" [patterns]="customPatterns"
                                       [validation]="false" [dropSpecialCharacters]="false" matInput
                                       formControlName="reserveMinutes">
                            </mat-form-field>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="order-payment-minutes">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Время оплаты заказа, мин." [state]="getSortDirection('orderPaymentMinutes')"
                                  (onChange)="onSortChange('orderPaymentMinutes', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3"
                                 *ngIf="editingLimit?.uuid !== element.uuid">{{element.orderPaymentMinutes}}</div>
                            <mat-form-field class="input-field" color="primary"
                                            *ngIf="editingLimit?.uuid === element.uuid">
                                <mat-label>Время оплаты</mat-label>
                                <input type="text" autocomplete="off" mask="099999999999" [patterns]="customPatterns"
                                       [validation]="false" [dropSpecialCharacters]="false" matInput
                                       formControlName="orderPaymentMinutes">
                            </mat-form-field>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="refund-minutes">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <sort label="Возврат, мин." [state]="getSortDirection('refundMinutes')"
                                  (onChange)="onSortChange('refundMinutes', $event)"></sort>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <div class="body-text-3"
                                 *ngIf="editingLimit?.uuid !== element.uuid">{{element.refundMinutes}}</div>
                            <mat-form-field class="input-field" color="primary"
                                            *ngIf="editingLimit?.uuid === element.uuid">
                                <mat-label>Время возврата</mat-label>
                                <input type="text" autocomplete="off" mask="099999999999" [patterns]="customPatterns"
                                       [validation]="false" [dropSpecialCharacters]="false" matInput
                                       formControlName="refundMinutes">
                            </mat-form-field>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                        <div class="mat-cell-content">
                            <button
                                    mat-icon-button
                                    disableRipple
                                    type="button"
                                    class="button-icon"
                                    color="primary"
                                    *ngIf="editingLimit?.uuid !== element.uuid"
                                    (click)="editLimit(element)"
                            >
                                <mat-icon svgIcon="pencil"></mat-icon>
                            </button>
                            <button
                                    mat-button
                                    disableRipple
                                    type="button"
                                    class="button-primary-basic-filled"
                                    *ngIf="editingLimit?.uuid === element.uuid"
                                    (click)="updateLimit(element)"
                                    [disabled]="form.invalid"
                                    color="primary"
                            >
                                Сохранить
                            </button>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: tableColumns;"
                         [class.row-editing]="editingLimit?.uuid === row.uuid"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>
        </div>
    </div>

    <div class="actions">
        <button
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled"
                color="success"
                (click)="next()"
        >
            Далее
        </button>
    </div>
</div>

<div class="page-container" *ngIf="loading">
    <div class="sale-limits-container">
        <div class="sale-limits-table">
            <mat-table [dataSource]="[1,2,3,4]">
                <ng-container matColumnDef="client">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="14" [width]="120"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="client-max-quantity">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="14" [width]="120"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="reserve-max-quantity">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="14" [width]="120"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="reserve-minutes">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="14" [width]="120"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="order-payment-minutes">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="14" [width]="120"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="refund-minutes">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="mat-cell-content">
                            <skeleton-text [height]="12" [width]="100"></skeleton-text>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content">
                            <skeleton-text [height]="14" [width]="120"></skeleton-text>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="mat-cell-content"></div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="empty-scrollbar-row">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>

                <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>

                <mat-footer-row class="empty-scrollbar-row"
                                *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
            </mat-table>
        </div>
    </div>

    <div class="actions">
        <skeleton-text></skeleton-text>
    </div>
</div>
