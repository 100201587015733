import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {CuUser, User} from '../../shared/types/user';
import {AbstractComponent} from '../../shared/abstract.component';
import {Profile} from '../../shared/types/profile';

export enum CuUserPopupResult {
    CANCEL,
    SAVE,
}

@Component({
    templateUrl: './cu-user-popup.component.html',
    styleUrls: ['./cu-user-popup.component.scss']
})
export class CuUserPopupComponent extends AbstractComponent implements OnInit {
    public form: FormGroup;

    public popupResult = CuUserPopupResult;

    public error: string | null = null;

    public loading = true;

    public profiles: Profile[] = [];

    constructor(
        private readonly dialogRef: MatDialogRef<CuUserPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly user: User,
    ) {
        super();

        const passwordValidators = [
            /*Validators.minLength(8),
            Validators.pattern(/(?=.*\d.*)(?=.*[a-z].*)(?=.*[A-Z].*)/),*/
        ];

        if (!this.user) {
            passwordValidators.push(Validators.required);
        }

        this.form = this.fb.group({
            uuid: [''],
            login: ['', [Validators.required]],
            name: ['', [Validators.required]],
            password: ['', passwordValidators],
            profiles: [[]],
        });

        if (this.user) {
            this.form.patchValue({
                uuid: this.user.uuid,
                name: this.user.name,
                login: this.user.login,
                profiles: this.user.profiles?.map(profile => profile.uuid) || [],
            });
        }
    }

    public ngOnInit(): void {
        this.loading = true;
        this.loadProfiles()
            .pipe(
                tap(() => {
                    this.loading = false;
                }),
                this.untilComponentDestroyed(),
            )
            .subscribe();
    }

    public close(result: CuUserPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public save(): void {
        const form = this.form.value;

        const model: CuUser = {
            uuid: form.uuid,
            login: form.login,
            name: form.name,
            password: form.password,
            profileUuids: form.profiles,
        };

        this.error = null;
        this.form.disable();

        const request$ = model.uuid ? this.httpClient.put<User>('/adminpanelapi/user/update', model)
            : this.httpClient.post<User>('/adminpanelapi/user/create', model);

        request$
            .pipe(
                tap(() => {
                    this.close(CuUserPopupResult.SAVE);
                }),
                catchError(error => {
                    this.error = error.error.message;
                    this.form.enable();
                    return of(error);
                }),
            )
            .subscribe();
    }

    private loadProfiles(): Observable<Profile[]> {
        return this.httpClient.get<Profile[]>('/adminpanelapi/profile/all')
            .pipe(
                tap(profiles => {
                    this.profiles = profiles;
                })
            );
    }
}
