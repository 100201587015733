export enum Sex {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export interface Client {
    uuid?: string;
    lastName?: string;
    middleName?: string;
    firstName?: string;
    phone?: string;
    email?: string;
    hasPassword?: boolean;
    createdAt?: string;
    updatedAt?: string;
    phoneConfirmed?: boolean;
    emailConfirmed?: boolean;
    agreementConfirmed?: boolean;
    advertisingConfirmed?: boolean;
    birthday?: string;
    birthDate?: string;
    sex?: Sex;
    address?: string;
    password?: string;
    notifications?: ClientNotifications;
}


export interface ClientNotifications {
    emailNotificationsEnabled: boolean;
    smsNotificationsEnabled: boolean;
    newsAndPromoNotificationsEnabled: boolean;
    discountsAndSalesNotificationsEnabled: boolean;
    internetStoreNotificationsEnabled: boolean;
    ordersAndRefundsNotificationsEnabled: boolean;
}
