import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DefaultErrorPopupComponent} from './default-error-popup.component';

@Injectable({
    providedIn: 'root'
})
export class DefaultErrorPopupService {
    constructor(
        private readonly matDialog: MatDialog
    ) {
    }

    public open(message: string): void {
        this.matDialog.open(DefaultErrorPopupComponent, {
            panelClass: 'primary-popup-panel',
            backdropClass: 'primary-popup-backdrop',
            data: message,
            restoreFocus: false,
            disableClose: true,
            autoFocus: false
        });
    }
}
