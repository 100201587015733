import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationConfig, ConfirmationPopupComponent} from './confirmation-popup.component';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationPopupService {
    constructor(
        private readonly matDialog: MatDialog
    ) {
    }

    public async confirm(config: ConfirmationConfig): Promise<boolean> {
        return this.matDialog.open(ConfirmationPopupComponent, {
            panelClass: ['primary-popup-panel', 'default-popup-panel'],
            backdropClass: 'primary-popup-backdrop',
            data: config,
            restoreFocus: false,
            disableClose: true,
            autoFocus: false
        }).afterClosed().toPromise();
    }
}
