import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ClientGroup} from '../../shared/types/client-group';

export enum CuClientGroupPopupResult {
    CANCEL,
    SAVE,
}

@Component({
    templateUrl: './cu-client-group-popup.component.html',
    styleUrls: ['./cu-client-group-popup.component.scss']
})
export class CuClientGroupPopupComponent {
    public form: FormGroup;

    public popupResult = CuClientGroupPopupResult;

    public error: string | null = null;

    constructor(
        private readonly dialogRef: MatDialogRef<CuClientGroupPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly clientGroup: ClientGroup,
    ) {
        this.form = this.fb.group({
            uuid: [null],
            name: ['', [Validators.required]],
        });

        if (this.clientGroup) {
            this.form.patchValue({
                uuid: this.clientGroup.uuid,
                name: this.clientGroup.name,
            });
        }
    }

    public close(result: CuClientGroupPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public save(): void {
        this.processSave(CuClientGroupPopupResult.SAVE);
    }

    private processSave(result: CuClientGroupPopupResult): void {
        const model = this.form.value as ClientGroup;

        this.error = null;
        this.form.disable();

        const request$ = model.uuid ?
            this.httpClient.put<ClientGroup>('/adminpanelapi/client-group/update', model)
            : this.httpClient.post<ClientGroup>('/adminpanelapi/client-group/create', model);

        request$
            .pipe(
                tap(() => {
                    this.close(result);
                }),
                catchError(error => {
                    this.error = error.error.message;
                    this.form.enable();
                    return of(error);
                }),
            )
            .subscribe();
    }
}
