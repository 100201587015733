import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {BaseCatalog} from '../base-catalog/base-catalog';

export interface ResourceType {
    name: string;
}

@Component({
    selector: 'app-resource-type-catalog',
    templateUrl: './resource-type-catalog.component.html',
    styleUrls: ['./resource-type-catalog.component.scss']
})
export class ResourceTypeCatalogComponent implements BaseCatalog<ResourceType> {

    public form: FormGroup;
    public waiting = false;

    @Output()
    create: EventEmitter<ResourceType> = new EventEmitter<ResourceType>();

    @Output()
    close: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient
    ) {
        this.form = this.fb.group({
            name: [null, Validators.required]
        });
    }

    public async add(): Promise<void> {
        this.form.disable();
        this.waiting = true;
        try {
            const model = {
                name: this.form.value.name
            };
            const eventType = await this.httpClient.post<ResourceType>(`/adminpanelapi/resource/types`, model).toPromise();
            this.create.emit(eventType);
            this.close.emit();
            this.form.enable();
        } catch (e) {
            this.form.enable();
            this.form.controls.name.setErrors({invalid: e.error.message});
        }
        this.waiting = false;
    }
}
