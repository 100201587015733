import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    Type,
} from '@angular/core';
import {CalendarService} from "./calendar.service";
import {BaseCell} from "./components/base-cell-component/base-cell";
import {BaseCellComponent} from "./components/base-cell-component/base-cell.component";

@Component({
    selector: 'calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnChanges {
    private currentDate = new Date();
    private _today = new Date();

    public today = new Date(this._today.getFullYear(), this._today.getMonth(), this._today.getDate()).getTime();
    public days: Date[] = [];
    public daysNext: Date[] = [];

    @Input() date: Date | null = null;

    @Input() cellComponent: Type<BaseCell> = BaseCellComponent;

    @Output() dateChange: EventEmitter<Date> = new EventEmitter(true);

    constructor(
        private calendarService: CalendarService
    ) {
        this.setCurrentDays();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.date.currentValue) {
            this.currentDate = changes.date.currentValue;
            this.setCurrentDays();
        }
    }

    prevMonth() {
        this.currentDate = this.calendarService.prevMonth(this.currentDate);
        this.setCurrentDays();
    }

    nextMonth() {
        this.currentDate = this.calendarService.nextMonth(this.currentDate);
        this.setCurrentDays();
    }

    public getMonthName() {
        return this.calendarService.getMonthName(this.currentDate);
    }

    public getYear() {
        return this.currentDate.getFullYear();
    }

    private setCurrentDays() {
        this.days = this.calendarService.createDaysGrid(new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1));
    }

    onDateChange(date: Date) {
        this.dateChange.emit(date);
    }
}
