import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Event, EventType, Team} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {EventTypeCatalogComponent} from '../../components/reference-book/event-type-catalog/event-type-catalog.component';
import {TeamCatalogComponent} from '../../components/reference-book/team-catalog/team-catalog.component';

export enum CuEventPopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

@Component({
    selector: 'app-cu-event-popup',
    templateUrl: './cu-event-popup.component.html',
    styleUrls: ['./cu-event-popup.component.scss']
})
export class CuEventPopupComponent implements OnInit {

    teamAOpened = false;
    teamBOpened = false;
    eventTypeOpened = false;

    form: FormGroup;

    private teams: Team[] = [];

    private eventTypes: EventType[] = [];

    public loading = true;
    public waiting = false;

    public eventTypeCatalogComponent = EventTypeCatalogComponent;
    public teamCatalogComponent = TeamCatalogComponent;

    public error: string | null = null;

    popupResult = CuEventPopupResult;

    constructor(
        private readonly dialogRef: MatDialogRef<CuEventPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly event: Event
    ) {
        this.form = this.fb.group({
            teamA: [null],
            teamB: [null],
            eventName: [null],
            eventCaption: [null],
            eventType: [null]
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await Promise.all([
            this.loadEventTypes(),
            this.loadTeams()
        ]);
        if (this.event) {
            this.form.patchValue({
                teamA: this.findTeamByUuid(this.event.teamAUuid),
                teamB: this.findTeamByUuid(this.event.teamBUuid),
                eventName: this.event.eventName,
                eventCaption: this.event.eventCaption,
                eventType: this.findEventTypeByUuid(this.event.eventTypeUuid)
            });
        }
        this.loading = false;
    }

    public close(result: CuEventPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public getTeams(): any[] {
        return this.teams;
    }

    public getEventTypes(): any[] {
        return this.eventTypes;
    }

    public onEventTypeCreate(eventType: EventType): void {
        this.eventTypes.push(eventType);
        this.form.controls.eventType.setValue(eventType);
    }

    public onTeamCreate(team: Team, controlName: string): void {
        this.teams.push(team);
        this.form.controls[controlName].setValue(team);
    }

    private async loadTeams(): Promise<void> {
        try {
            const teams = await this.httpClient.get<Team[]>(`/adminpanelapi/team/all`).toPromise();
            this.teams = [{name: 'Не выбрано'} as Team, ...teams];
        } catch (e) {

        }
    }

    private async loadEventTypes(): Promise<void> {
        try {
            this.eventTypes = await this.httpClient
                .get<EventType[]>(`/adminpanelapi/event-type/all`, {params: {consistType: 'TICKET'}}).toPromise();
        } catch (e) {

        }
    }

    public async save(): Promise<void> {
        try {
            await this._save();
            this.close(CuEventPopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save(): Promise<Event> {
        const model: any = {
            caption: this.form.value.eventCaption,
            consistType: 'TICKET',
            eventTypeUuid: this.form.value.eventType.uuid,
            name: this.form.value.eventName,
            teamAUuid: this.form.value.teamA ? this.form.value.teamA.uuid : null,
            teamBUuid: this.form.value.teamB ? this.form.value.teamB.uuid : null
        };
        if (this.event) {
            model.uuid = this.event.uuid;
        }
        this.waiting = true;
        this.error = null;
        this.form.disable();
        try {
            if (this.event) {
                return await this.httpClient.put<Event>(`/adminpanelapi/event/update`, model).toPromise();
            } else {
                return await this.httpClient.post<Event>(`/adminpanelapi/event/create`, model).toPromise();
            }
        } catch (e) {
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }


    public async saveAndCreatEventSession(): Promise<void> {
        try {
            const event = await this._save();
            this.close(CuEventPopupResult.SAVE_AND_CREATE, event);
        } catch (e) {

        }
    }

    private findTeamByUuid(uuid: string): Team | null {
        return this.teams.find(team => team.uuid === uuid) || null;
    }

    private findEventTypeByUuid(uuid: string): EventType | null {
        return this.eventTypes.find(eventType => eventType.uuid === uuid) || null;
    }
}
