import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Client, Sex} from '../../shared/types/client';
import {format} from 'date-fns';

export enum CreateClientPopupResult {
    CANCEL,
    SAVE,
}

@Component({
    templateUrl: './create-client-popup.component.html',
    styleUrls: ['./create-client-popup.component.scss']
})
export class CreateClientPopupComponent {
    public form: FormGroup;

    public popupResult = CreateClientPopupResult;

    public Sex = Sex;

    public error: string | null = null;

    constructor(
        private readonly dialogRef: MatDialogRef<CreateClientPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
    ) {
        this.form = this.fb.group({
            firstName: [''],
            lastName: ['', [Validators.required]],
            middleName: [''],
            email: ['', [
                Validators.email
            ]],
            phone: ['', [
                Validators.pattern(/^\+\d{9,15}$/)
            ]],
            birthday: [null],
            address: [''],
            sex: [null],
            password: ['', [
                Validators.minLength(8),
                Validators.pattern(/(?=.*\d.*)(?=.*[a-z].*)(?=.*[A-Z].*)/),
            ]],
        });
    }

    public onBirthdayChange(date: Date | null): void {
        this.form.controls.birthday.setValue(date);
    }

    public close(result: CreateClientPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public save(): void {
        const form = this.form.value;

        const model: Client = {
            lastName: form.lastName,
            middleName: form.middleName || undefined,
            firstName: form.firstName || undefined,
            phone: form.phone || undefined,
            email: form.email || undefined,
            birthDate: form.birthday ? format(form.birthday, 'yyyy-MM-dd') : undefined,
            sex: form.sex || undefined,
            address: form.address,
            password: form.password || undefined,
        };

        this.error = null;
        this.form.disable();

        this.httpClient.post<Client>('/adminpanelapi/client', model)
            .pipe(
                tap(() => {
                    this.close(CreateClientPopupResult.SAVE);
                }),
                catchError(error => {
                    this.error = error.error.message;
                    this.form.enable();
                    return of(error);
                }),
            )
            .subscribe();
    }
}
