<div class="page-container" *ngIf="!loading">
    <div class="filters-row filters-primary">
        <div class="filters-group">
            <div class="filters-group-filter">
                <button
                    mat-raised-button
                    disableRipple
                    type="button"
                    class="button-primary-filled button-primary-filled-secondary button-primary-filled-with-icon"
                    color="primary"
                    (click)="createClientLimit()"
                >
                    <mat-icon svgIcon="plus" class="mat-button-icon_left"></mat-icon>
                    Создать
                </button>
            </div>
        </div>
    </div>
    <div class="table-wrapper">
        <mat-table [dataSource]="dataSource" appSimplebarScroller [scrollWithMouse]="true">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>
                    <div class="mat-cell-content">
                        <span class="body-text-3" style="color: #506F85;">Название группы</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="mat-cell-content">
                        <div class="client-groups">
                            <div *ngFor="let clientGroup of element.clientGroups" class="client-group">{{clientGroup.name}}</div>
                        </div>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>
                    <div class="mat-cell-content">
                        <span class="body-text-3" style="color: #506F85;">Период действия</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="mat-cell-content">
                        <div class="range-container">
                            <div>
                                <div class="body-text-3">{{element.dateBegin}}</div>
                                <div>
                                    <mat-icon svgIcon="time"></mat-icon>
                                    <span class="body-text-3">{{element.timeBegin}}</span>
                                </div>
                            </div>
                            <span>-</span>
                            <div>
                                <div class="body-text-3">{{element.dateEnd}}</div>
                                <div>
                                    <mat-icon svgIcon="time"></mat-icon>
                                    <span class="body-text-3">{{element.timeEnd}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="actions" stickyEnd>
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                    <div class="mat-cell-content">
                        <button
                            mat-icon-button
                            disableRipple
                            type="button"
                            class="button-icon"
                            color="primary"
                            (click)="editClientLimit(element)"
                        >
                            <mat-icon svgIcon="pencil"></mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            disableRipple
                            type="button"
                            class="button-icon"
                            color="primary"
                            (click)="removeClientLimit(element)"
                        >
                            <mat-icon svgIcon="basket"></mat-icon>
                        </button>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="empty-scrollbar-row">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element"></mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>

            <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>

            <mat-footer-row class="empty-scrollbar-row" *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
        </mat-table>
    </div>

    <div class="actions">
        <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled"
            color="success"
            (click)="next()"
        >
            Далее
        </button>
    </div>
</div>
<div class="page-container" *ngIf="loading">
    <div class="filters-row filters-primary">
        <div class="filters-group">
            <div class="filters-group-filter">
                <skeleton-text [radius]="8" [width]="114" [height]="36"></skeleton-text>
            </div>
        </div>
    </div>
    <div class="table-wrapper">
        <mat-table [dataSource]="dataSource" appSimplebarScroller [scrollWithMouse]="true">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>
                    <div class="mat-cell-content">
                        <skeleton-text [height]="12" [width]="90"></skeleton-text>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="mat-cell-content">
                        <skeleton-text [height]="12" [width]="90"></skeleton-text>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>
                    <div class="mat-cell-content">
                        <skeleton-text [height]="12" [width]="90"></skeleton-text>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="mat-cell-content">
                        <skeleton-text [height]="12" [width]="90"></skeleton-text>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="actions" stickyEnd>
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                    <div class="mat-cell-content">
                        <skeleton-text></skeleton-text>
                        <skeleton-text></skeleton-text>
                    </div>
                </mat-cell>
                <mat-footer-cell *matFooterCellDef> </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="empty-scrollbar-row">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element"></mat-cell>
                <mat-footer-cell *matFooterCellDef></mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></mat-header-row>

            <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>

            <mat-footer-row class="empty-scrollbar-row" *matFooterRowDef="['empty-scrollbar-row'];"></mat-footer-row>
        </mat-table>
    </div>

    <div class="actions">
        <skeleton-text [radius]="8" [width]="86" [height]="36"></skeleton-text>
    </div>
</div>
