import {Component, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EventSessionSettingsService, SettingsLoadingStatus, SettingsPopupState} from '../event-session-settings.service';
import {Observable, of, Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {switchMap} from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FullscreenLoaderService} from '../../../../shared/ui-kit/fullscreen-loader/fullscreen-loader.service';

interface DeviceModel {
    deviceType: 'CARD_PRINTER' | 'FACE_ID' | 'FISCAL_PRINTER' | 'GRAFIC_PRINTER' | 'IMAGE_SCANNER' | 'PDF' | 'POS_TERMINAL' | 'RECEIPT_PRINTER' | 'RFID_READER' | 'TICKET_PRINTER';
    name: string;
    speed: number;
    uuid: string;
}

interface PrintImage {
    deviceModelName: string;
    deviceModelUuid: string;
    fileLength: number;
    name: string;
    note: string;
    scriptLength: number;
    uuid: string;
}

interface Category {
    caption?: string;
    name: string;
    uuid: string | null;
}

interface Zone {
    color?: string;
    locationName?: string;
    locationUuid?: string;
    name: string;
    uuid: string | null;
}

interface SessionPrintImage {
    categoryName: string;
    categoryUuid: string;
    deviceModelName: string;
    deviceModelUuid: string;
    eventSessionUuid: string;
    printImageName: string;
    printImageUuid: string;
    uuid: string;
    zoneColor: string;
    zoneName: string;
    zoneUuid: string;

    selected: boolean;
}

@Component({
    selector: 'app-ticket-layouts',
    templateUrl: './ticket-layouts.component.html',
    styleUrls: ['./ticket-layouts.component.scss']
})
export class TicketLayoutsComponent implements OnDestroy {

    deviceModelOpened = false;
    printImageOpened = false;
    categoryOpened = false;
    zoneOpened = false;

    private deviceModels: DeviceModel[] = [];
    private printImages: PrintImage[] = [];
    private categories: Category[] = [];
    private zones: Zone[] = [];
    public sessionPrintImages: SessionPrintImage[] = [];
    public selectedSessionPrintImages: SessionPrintImage[] = [];

    private readonly eventSessionLoadingSubscription: Subscription;
    private readonly deviceModelSubscription: Subscription;
    private readonly printImageSubscription: Subscription;

    public readonly form: FormGroup;

    public tableColumns: string[] = ['select-row', 'printer', 'print-image', 'category', 'zone', 'actions'];

    public error: string | null = null;

    public loading = true;

    constructor(
        private readonly httpClient: HttpClient,
        private readonly eventSessionSettingsService: EventSessionSettingsService,
        private readonly fb: FormBuilder,
        private readonly fullscreenLoaderService: FullscreenLoaderService
    ) {
        this.eventSessionLoadingSubscription = this.eventSessionSettingsService.loading.subscribe(async (status) => {
            if (status === SettingsLoadingStatus.LOADED) {
                const eventLocationUuid = this.eventSessionSettingsService.getEventSession()?.locationUuid as string;
                const eventSessionUuid = this.eventSessionSettingsService.getEventSessionUuid() as string;
                await Promise.all([
                    this.loadDeviceModels(),
                    this.loadCategories(),
                    this.loadZones(eventLocationUuid),
                    this.loadSessionPrintImages(eventSessionUuid)
                ]);
                this.loading = false;
            }
        });

        this.form = this.fb.group({
            deviceModel: [null, [Validators.required]],
            printImage: [{
                value: null,
                disabled: true
            }, [Validators.required]],
            category: [{
                value: null,
                disabled: true
            }, [Validators.required]],
            zone: [{
                value: null,
                disabled: true
            }, [Validators.required]]
        });

        this.deviceModelSubscription = this.form.controls.deviceModel.valueChanges
            .pipe(
                switchMap(() => {
                    const deviceModel = this.form.controls.deviceModel.value;
                    if (deviceModel === null) {
                        return of([]);
                    }
                    this.form.controls.printImage.disable();
                    this.form.controls.printImage.setValue(null);
                    this.form.controls.category.disable();
                    this.form.controls.category.setValue(null);
                    this.form.controls.zone.disable();
                    this.form.controls.zone.setValue(null);
                    const deviceModelUuid = deviceModel.uuid;
                    return this.loadPrintImages(deviceModelUuid);
                })
            )
            .subscribe((printImages) => {
                this.printImages = printImages;
                this.form.controls.printImage.enable();
            });

        this.printImageSubscription = this.form.controls.printImage.valueChanges
            .subscribe(() => {
                if (this.form.controls.printImage.value !== null) {
                    this.form.controls.category.enable();
                    this.form.controls.zone.enable();
                }
            });

    }

    ngOnDestroy(): void {
        this.eventSessionLoadingSubscription.unsubscribe();
        this.deviceModelSubscription.unsubscribe();
        this.printImageSubscription.unsubscribe();
    }

    private async loadDeviceModels(): Promise<void> {
        try {
            this.deviceModels = await this.httpClient.get<DeviceModel[]>(`/adminpanelapi/device-model/all`,
                {params: {deviceTypes: 'GRAFIC_PRINTER,TICKET_PRINTER,PDF,CARD_PRINTER'}}
            ).toPromise();
        } catch (e) {

        }
    }

    private loadPrintImages(deviceModelUuid: string): Observable<PrintImage[]> {
        return this.httpClient.get<PrintImage[]>(`/adminpanelapi/print-image/all`,
            {params: {deviceModelUuids: deviceModelUuid}}
        );
    }

    private async loadCategories(): Promise<void> {
        try {
            const categories = await this.httpClient.get<Category[]>(`/adminpanelapi/category/all`).toPromise();
            this.categories = [{name: 'Все', uuid: null}, ...categories];
        } catch (e) {

        }
    }

    private async loadZones(locationUuid: string): Promise<void> {
        try {
            const zones = await this.httpClient.get<Zone[]>(`/adminpanelapi/zone/all`, {
                params: {locationUuids: locationUuid}
            }).toPromise();

            this.zones = [{name: 'Все', uuid: null}, ...zones];
        } catch (e) {

        }
    }

    public getDeviceModels(): DeviceModel[] {
        return this.deviceModels;
    }

    public getPrintImages(): PrintImage[] {
        return this.printImages;
    }

    public getCategories(): Category[] {
        return this.categories;
    }

    public getZones(): Zone[] {
        return this.zones;
    }

    public resetForm(): void {
        this.form.reset();
        this.form.controls.printImage.disable();
        this.form.controls.category.disable();
        this.form.controls.zone.disable();
        this.error = null;
    }

    private async loadSessionPrintImages(eventSessionUuid: string): Promise<void> {
        try {
            this.sessionPrintImages = await this.httpClient
                .get<SessionPrintImage[]>(`/adminpanelapi/event-session-print-image/all/by-event-session/${eventSessionUuid}`).toPromise();
        } catch (e) {

        }
    }

    public async addSessionPrintImage(): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            const model = {
                categoryUuid: this.form.value.category.uuid,
                eventSessionUuid: this.eventSessionSettingsService.getEventSessionUuid() as string,
                printImageUuid: this.form.value.printImage.uuid,
                zoneUuid: this.form.value.zone.uuid
            };
            const sessionPrintImage = await this.httpClient
                .post<SessionPrintImage>(`/adminpanelapi/event-session-print-image/create`, model).toPromise();
            this.sessionPrintImages = [...this.sessionPrintImages, sessionPrintImage];

            this.resetForm();
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public togglePrintImageSelection(sessionPrintImage: SessionPrintImage): void {
        if (sessionPrintImage.selected) {
            const index = this.selectedSessionPrintImages.indexOf(sessionPrintImage);
            if (index > -1) {
                this.selectedSessionPrintImages.splice(index, 1);
            }
        } else {
            this.selectedSessionPrintImages.push(sessionPrintImage);
        }
        sessionPrintImage.selected = !sessionPrintImage.selected;
    }

    public onSelectAllChange(matCheckboxChange: MatCheckboxChange): void {
        if (matCheckboxChange.checked) {
            this.selectedSessionPrintImages = [...this.sessionPrintImages];
        } else {
            this.selectedSessionPrintImages = [];
        }

        this.sessionPrintImages.forEach(sessionPrintImage => {
            sessionPrintImage.selected = matCheckboxChange.checked;
        });
    }

    public async removeSelectedPrintImages(): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        const selectedSessionPrintImageUuids = this.selectedSessionPrintImages.map(sessionPrintImage => sessionPrintImage.uuid);
        try {
            await this.httpClient
                .delete(`/adminpanelapi/event-session-print-image/delete/${selectedSessionPrintImageUuids.join(',')}`).toPromise();

            this.sessionPrintImages = this.sessionPrintImages.filter(sessionPrintImage => !sessionPrintImage.selected);
            this.selectedSessionPrintImages = [];
        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public async removePrintImage(sessionPrintImage: SessionPrintImage): Promise<void> {
        this.error = null;
        this.fullscreenLoaderService.open();
        try {
            await this.httpClient
                .delete(`/adminpanelapi/event-session-print-image/delete/${sessionPrintImage.uuid}`).toPromise();

            this.sessionPrintImages.splice(this.sessionPrintImages.indexOf(sessionPrintImage), 1);
            this.sessionPrintImages = [...this.sessionPrintImages];

            if (sessionPrintImage.selected) {
                this.selectedSessionPrintImages.splice(this.selectedSessionPrintImages.indexOf(sessionPrintImage), 1);
                this.selectedSessionPrintImages = [...this.selectedSessionPrintImages];
            }

        } catch (e) {
            this.error = e.error.message;
        }
        this.fullscreenLoaderService.close();
    }

    public next(): void {
        this.eventSessionSettingsService.setCurrentState(SettingsPopupState.PRICE_RATES);
    }
}
