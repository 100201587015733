import { Component, OnDestroy } from '@angular/core';

import { Observable, ReplaySubject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    template: '',
})
export abstract class AbstractComponent implements OnDestroy {
    private readonly onDestroySubscription$ = new ReplaySubject<boolean>();

    public ngOnDestroy(): void {
        this.onDestroySubscription$.next(true);
        this.onDestroySubscription$.complete();
    }

    protected untilComponentDestroyed<T>(): (source$: Observable<T>) => Observable<T> {
        return (source$: Observable<T>) => source$.pipe(takeUntil(this.onDestroySubscription$));
    }

    protected setTimeout$(timeoutMs: number, untilComponentDestroyed = true): Observable<any> {
        const t = timer(timeoutMs);

        if (untilComponentDestroyed) {
            t.pipe(this.untilComponentDestroyed());
        }

        return t;
    }
}
