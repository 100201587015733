import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {EventSessionSettingsService, SettingsLoadingStatus, SettingsPopupState} from './event-session-settings.service';
import {Subscription} from 'rxjs';
import {EventSessionFull} from '../../shared/types/events';
import {AbstractComponent} from '../../shared/abstract.component';
import {first, tap} from 'rxjs/operators';

@Component({
    selector: 'app-event-session-settings-popup',
    templateUrl: './event-session-settings-popup.component.html',
    styleUrls: ['./event-session-settings-popup.component.scss']
})
export class EventSessionSettingsPopupComponent extends AbstractComponent implements OnInit, OnDestroy {

    public loading = true;
    private loadingSubscription: Subscription;

    public state = SettingsPopupState;

    constructor(
        private readonly dialogRef: MatDialogRef<EventSessionSettingsPopupComponent>,
        private readonly eventSessionSettingsService: EventSessionSettingsService
    ) {
        super();

        this.loadingSubscription = this.eventSessionSettingsService.loading.subscribe((value) => {
            if (value === SettingsLoadingStatus.LOADED) {
                this.loading = false;
            }
        });
    }

    public ngOnInit(): void {
        this.eventSessionSettingsService.close$
            .pipe(
                first(),
                tap(() => {
                    this.close();
                }),
                this.untilComponentDestroyed(),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.loadingSubscription.unsubscribe();
    }

    public getCurrentState(): SettingsPopupState | null {
        return this.eventSessionSettingsService.getCurrentState();
    }

    public getEventSession(): EventSessionFull | null {
        return this.eventSessionSettingsService.getEventSession();
    }

    public setTicketConsistState(): void {
        const eventSession = this.eventSessionSettingsService.getEventSession();
        if (eventSession === null) {
            return;
        }
        if (eventSession.consistType === 'TICKET') {
            this.eventSessionSettingsService.setCurrentState(SettingsPopupState.EVENT_TICKET_CONSISTS);
        } else {
            this.eventSessionSettingsService.setCurrentState(SettingsPopupState.SEASON_TICKET_CONSISTS);
        }
    }

    public setInfoState(): void {
        const eventSession = this.eventSessionSettingsService.getEventSession();
        if (eventSession === null) {
            return;
        }
        if (eventSession.consistType === 'TICKET') {
            this.eventSessionSettingsService.setCurrentState(SettingsPopupState.EVENT_SESSION_INFO);
        } else {
            this.eventSessionSettingsService.setCurrentState(SettingsPopupState.SUBSCRIPTION_SESSION_INFO);
        }
    }

    public setCurrentState(state: SettingsPopupState): void {
        this.eventSessionSettingsService.setCurrentState(state);
    }

    close(): void {
        this.dialogRef.close();
    }
}
