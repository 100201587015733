import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Contract} from '../../shared/types/contracts';
import {catchError, startWith, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Company} from '../../shared/types/companies';
import {AbstractComponent} from '../../shared/abstract.component';
import {format, parse} from 'date-fns';

export enum CuContractPopupResult {
    CANCEL,
    SAVE,
}

interface PopupData {
    contract?: Contract;
    companyUuid?: string;
    companies: Company[];
}

@Component({
    templateUrl: './cu-contract-popup.component.html',
    styleUrls: ['./cu-contract-popup.component.scss'],
})
export class CuContractPopupComponent extends AbstractComponent implements OnInit {
    public form: FormGroup;

    public popupResult = CuContractPopupResult;

    public error: string | null = null;

    public searchOpened = false;

    public companies: Company[] = [{name: 'Не выбрано'}];

    constructor(
        private readonly dialogRef: MatDialogRef<CuContractPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly data: PopupData
    ) {
        super();

        this.form = this.fb.group({
            uuid: [null],
            company: [null, [Validators.required]],
            companySearchQuery: [''],
            name: ['', [Validators.required]],
            date: [null, [Validators.required]],
            dateBegin: [null],
            dateEnd: [null],
            amount: [''],
            manager: [''],
            description: [''],
        });

        if (this.data.contract) {
            this.form.patchValue({
                uuid: this.data.contract.uuid,
                companyUuid: this.data.contract.companyUuid,
                name: this.data.contract.name,
                date: this.data.contract.date ? this.parseDate(this.data.contract.date) : null,
                dateBegin: this.data.contract.dateBegin ? this.parseDate(this.data.contract.dateBegin) : null,
                dateEnd: this.data.contract.dateEnd ? this.parseDate(this.data.contract.dateEnd) : null,
                amount: this.data.contract.description?.amount,
                manager: this.data.contract.description?.manager,
                description: this.data.contract.description?.description,
            });
        }

        if (this.data.companyUuid || this.data.contract) {
            const companyUuid = this.data.companyUuid || this.data.contract?.companyUuid;
            const company = this.data.companies.find(c => c.uuid === companyUuid);
            if (company) {
                this.form.patchValue({
                    company
                });
            }
        }
    }

    public ngOnInit(): void {
        this.form.controls.companySearchQuery.valueChanges
            .pipe(
                startWith(''),
                tap(v => {
                    const query = v.toLowerCase();
                    this.companies = this.data.companies.filter(company => {
                        return company.name?.toLowerCase().includes(query)
                            || company.inn?.toString().toLowerCase().includes(query)
                            || company.address?.toLowerCase().includes(query)
                            || company.contact?.toLowerCase().includes(query)
                            || company.email?.toLowerCase().includes(query)
                            || company.phone?.toLowerCase().includes(query)
                            || company.note?.toLowerCase().includes(query);
                    });

                    if (this.companies.length === 0) {
                        this.companies = [{name: 'Не выбрано'}];
                    }
                }),
                this.untilComponentDestroyed()
            )
            .subscribe();
    }

    public get displayCompanyControl(): boolean {
        return !this.data.companyUuid && !this.data.contract;
    }

    public onDateChange(date: Date | null): void {
        this.form.controls.date.setValue(date);
    }

    public onDateBeginChange(date: Date | null): void {
        this.form.controls.dateBegin.setValue(date);
    }

    public onDateEndChange(date: Date | null): void {
        this.form.controls.dateEnd.setValue(date);
    }

    public close(result: CuContractPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public save(): void {
        this.processSave(CuContractPopupResult.SAVE);
    }

    private processSave(result: CuContractPopupResult): void {
        const form = this.form.value;

        const model: Contract = {
            uuid: form.uuid,
            companyUuid: form.company.uuid,
            date: format(form.date, 'yyyy-MM-dd'),
            dateBegin: form.dateBegin ? format(form.dateBegin, 'yyyy-MM-dd') : undefined,
            dateEnd: form.dateEnd ? format(form.dateEnd, 'yyyy-MM-dd') : undefined,
            name: form.name,
            description: {
                amount: form.amount,
                description: form.description,
                manager: form.manager,
            }
        };

        this.error = null;
        this.form.disable();

        const request$ = model.uuid ?
            this.httpClient.put<Contract>('/adminpanelapi/contract/update', model)
            : this.httpClient.post<Contract>('/adminpanelapi/contract/create', model);

        request$
            .pipe(
                tap(() => {
                    this.close(result);
                }),
                catchError(error => {
                    this.error = error.error.message;
                    this.form.enable();
                    return of(error);
                }),
            )
            .subscribe();
    }

    private parseDate(dateString: string): Date {
        const d = new Date(`${dateString}T00:00:00Z`);
        const tzOffset = d.getTimezoneOffset();
        d.setMinutes(d.getMinutes() + tzOffset);
        return d;
    }
}
