<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div *ngIf="blankStores.action === 'add'" class="body-text-1">Получение новых бланков на склад</div>
        <div *ngIf="blankStores.action === 'writeOff'" class="body-text-1">Списание бланков</div>
        <div *ngIf="blankStores.action === 'issue'" class="body-text-1">Передача бланков кассиру</div>
        <div *ngIf="blankStores.action === 'receive'" class="body-text-1">Получение бланков от кассира</div>
        <div *ngIf="blankStores.action === 'issueORwriteOff'" class="body-text-1">Передача или списание бланков</div>
        <button
                mat-icon-button
                disableRipple
                type="button"
                class="button-icon"
                color="primary"
                (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    
    <div class="popup-body" [formGroup]="form">
        <mat-form-field *ngIf="blankStores.action != 'add' && blankStores.action != 'writeOff'" style="width: 250px; padding-top: 21.5px; padding-right: 16px;" appearance="fill" color="primary" class="input-select" 
                        [class.input-select__opened]="storeOpened">
                    <mat-label style="color: #839EB3;">Кассир*</mat-label>
                    <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                    <mat-select #storeSelect (openedChange)="storeOpened = $event" disableRipple
                            formControlName="uuid" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']">
                        <div appSimplebarScroller>
                            <mat-option *ngFor="let item of getStorehouses()" [value]="item.uuid">
                                {{item.storehouseName}}
                            </mat-option>
                        </div>
                     </mat-select>
        </mat-form-field>
        <div class="body-text-2 teams-title">Введите параметры пачки:</div>
        
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Серия*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="serial">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Номер С*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="numberStart">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Номер ПО*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="numberEnd">
            </mat-form-field>
        </div>
        <!-- <div class="popup-body-row">
            <mat-form-field appearance="fill" color="primary" class="input-select"
                            [class.input-select__opened]="eventTypeOpened">
                <mat-label>Тип мероприятия*</mat-label>
                <mat-icon svgIcon="dropdown-arrow" matSuffix></mat-icon>
                <mat-select #eventTypeSelect (openedChange)="eventTypeOpened = $event" disableRipple
                            formControlName="eventType" disableOptionCentering
                            [panelClass]="['dropdown-panel', 'dropdown-panel-advanced']">
                    <div appSimplebarScroller>
                        <mat-option *ngFor="let eventType of getEventTypes()" [value]="eventType">
                            {{eventType.name}}
                        </mat-option>
                    </div>
                    <div class="create-container" *ngIf="eventTypeOpened">
                        <app-base-catalog
                                [placeholder]="'Добавить новый тип мероприятия'"
                                [component]="eventTypeCatalogComponent"
                                (create)="onEventTypeCreate($event); eventTypeSelect.close()"></app-base-catalog>
                    </div>
                </mat-select>
            </mat-form-field>
        </div> -->
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer" [ngClass]="{issueORwriteOff: blankStores.action === 'issueORwriteOff'}">
        <button *ngIf="blankStores.action != 'issueORwriteOff'" style="padding-left: 16px;"
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                [disabled]="form.invalid || waiting"
                (click)="save()"
        >
            Сохранить
        </button>
        <button *ngIf="blankStores.action === 'issueORwriteOff'" style="padding-left: 16px;"
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                [disabled]="form.invalid || waiting || !form.value.uuid"
                (click)="save('issue')"
        >
            Передать
        </button>
        <button *ngIf="blankStores.action === 'issueORwriteOff'" style="background: red; padding-left: 16px;"
                mat-raised-button
                disableRipple
                type="button"
                class="button-primary-filled button-primary-filled-secondary"
                color="primary"
                [disabled]="form.invalid || waiting || form.value.uuid"
                (click)="save('writeOff')"
        >
            Списать
        </button>
        <!-- <button
                mat-stroked-button
                disableRipple
                type="button"
                class="button-primary-outline"
                color="primary"
                [disabled]="form.invalid || waiting"
                (click)="saveAndCreatEventSession()"
        >
            Сохранить и добавить событие
        </button> -->
    </div>
</div>

<div class="popup-wrapper" *ngIf="loading">
    <div class="popup-header">
        <div class="body-text-1">
            <skeleton-text [inverseColor]="true" [width]="192" [height]="18"></skeleton-text>
        </div>
    </div>
    <div class="popup-body">
        <div class="body-text-2 teams-title">
            <skeleton-text [width]="250" [height]="18"></skeleton-text>
        </div>
        <div class="popup-body-row popup-body-row-2">
            <skeleton-text [width]="220" [height]="44"></skeleton-text>
            <skeleton-text [width]="220" [height]="44"></skeleton-text>
        </div>
        <div class="popup-body-row">
            <skeleton-text [height]="44"></skeleton-text>
        </div>
        <div class="popup-body-row popup-body-row-with-hint">
            <skeleton-text [height]="44"></skeleton-text>
            <skeleton-text [width]="192" [height]="14"></skeleton-text>
        </div>
        <div class="popup-body-row">
            <skeleton-text [height]="44"></skeleton-text>
        </div>
    </div>
    <div class="popup-footer">
        <skeleton-text [height]="36"></skeleton-text>
        <skeleton-text [height]="36"></skeleton-text>
    </div>
</div>

