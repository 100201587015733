<div class="popup-close-container">
    <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            [disabled]="waiting"
            (click)="close()"
    >
        <mat-icon svgIcon="times"></mat-icon>
    </button>
</div>

<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div class="popup-header-title-container">
            <div class="body-text-1">История продажи места <strong>{{data.ticket?.locationSchemeSeatsOutgroupPrefix + ' ' + data.ticket?.locationSchemeSeatsOutgroupName + ' ' + data.ticket?.locationSchemeSeatsIngroupPrefix + ' ' + data.ticket?.locationSchemeSeatsIngroupName + ' ' + data.ticket?.locationSchemeSeatPrefix + ' ' + data.ticket?.locationSchemeSeatName}}</strong></div>
            <div class="body-text-1">на мероприятие <strong>{{data.eventCaption}} {{formatDateforDate(data?.eventSessionDateTimeBegin)}}</strong></div>
        </div>
    </div>
    <div class="popup-body">
        <div class="popup-body-table-container" appSimplebarScroller>   
            <div class="limits-header">
                <div class="limit-col-order-num body-text-2">Заказ</div>
                <div class="limit-col-date body-text-2">Дата заказа</div>
                <div class="limit-col-order-status body-text-2">Статус заказа</div>
                <div class="limit-col-pay-status body-text-2">Статус оплаты</div>
                <div class="limit-col-order-note body-text-2">Примечание</div>
                <div class="limit-col-creator body-text-2">Создан</div>
                <div class="limit-col-event-caption body-text-2">Билет</div>
                <div class="limit-col-amount body-text-2">Стоимость</div>
                <div class="limit-col-date body-text-2">Дата чека</div>
                <div class="limit-col-paymean body-text-2">Вид оплаты</div>
                <div class="limit-col-receipt-type body-text-2">Тип чека</div>
                <div class="limit-col-casher body-text-2">Кассир</div>
                <div class="limit-col-date body-text-2">Дата билета</div>
                <div class="limit-col-ticket-type body-text-2">Статус билета</div>
                <div class="limit-col-barcode body-text-2">Номер билета</div>
            </div>
            <div class="limits-row" *ngFor="let row of table" [ngClass]="{'active-ticket-row': row?.ticket_type === 'ACTIVE'}">
                <div class="limit-col-order-num body-text-2" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.order_num}}</div>
                <div class="limit-col-date body-text-3" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.order_datetime}}</div>
                <div class="limit-col-order-status body-text-2" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{orderStatusName(row?.order_status)}}</div>
                <div class="limit-col-pay-status body-text-3" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{payStatusName(row?.pay_status)}}</div>
                <div class="limit-col-order-note body-text-2" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.note}}</div>
                <div class="limit-col-creator body-text-3" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.creator_customer_name}}. {{row?.creator_client}}{{row?.creator_user}}</div>
                <div class="limit-col-event-caption body-text-2" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.event_caption}} {{row?.event_datetime_begin}}</div>
                <div class="limit-col-amount body-text-3" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.amount}}</div>
                <div class="limit-col-date body-text-2" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.receipt_datetime}}</div>
                <div class="limit-col-paymean body-text-3" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{paymeanName(row?.pay_mean)}}</div>
                <div class="limit-col-receipt-type body-text-2" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{receiptTypeName(row?.receipt_type)}}</div>
                <div class="limit-col-casher body-text-3" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.receipt_user_name}}</div>
                <div class="limit-col-date body-text-2" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.ticket_datetime}}</div>
                <div class="limit-col-ticket-type body-text-3" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{ticketTypeName(row?.ticket_type)}}</div>
                <div class="limit-col-barcode body-text-2" [ngClass]="{'active-ticket': row?.ticket_type === 'ACTIVE'}">{{row?.barcode}}</div>
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="popup-footer-alert-container">
            <inline-alert-component [text]="error" *ngIf="error !== null"></inline-alert-component>
        </div>
        <div class="popup-footer-actions">
            
            <button
                    mat-raised-button
                    disableRipple
                    type="button"
                    class="button-primary-filled"
                    [disabled]="waiting || table.length === 0"
                    color="primary"
                    (click)="close()"
            >
                Закрыть
            </button>
        </div>
    </div>
</div>

<div class="popup-wrapper" *ngIf="loading">
    <div class="popup-header">
        <div class="popup-header-title-container">
            <div class="headline-1">
                <skeleton-text [width]="270" [height]="34"></skeleton-text>
            </div>
            <div class="body-text-3 popup-header-subtitle">
                <skeleton-text [width]="150" [height]="14"></skeleton-text>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <div class="popup-body-form-container">
            <div class="client-input-wrapper">
                <skeleton-text [width]="261" [height]="44" [inverseColor]="true"></skeleton-text>
            </div>
            <div class="date-input-wrapper">
                <skeleton-text [width]="261" [height]="44" [inverseColor]="true"></skeleton-text>
            </div>
            <div class="add-button-wrapper">
                <skeleton-text [width]="134" [height]="44" [inverseColor]="true"></skeleton-text>
            </div>
        </div>
        <div class="popup-body-table-container" appSimplebarScroller>
            <div class="limits-row" *ngFor="let limit of [1,2,3]">
                <div class="limit-col-client body-text-2">
                    <skeleton-text [width]="100" [height]="18"></skeleton-text>
                </div>
                <div class="limit-col-date body-text-3">
                    <skeleton-text [width]="200" [height]="16"></skeleton-text>
                </div>
                <div class="limit-col-actions"></div>
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="popup-footer-alert-container"></div>
        <div class="popup-footer-actions">
            <skeleton-text [width]="160" [height]="32"></skeleton-text>
            <skeleton-text [width]="200" [height]="44"></skeleton-text>
        </div>
    </div>
</div>
