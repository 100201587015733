<div class="popup-wrapper" *ngIf="!loading">
    <div class="popup-header">
        <div class="body-text-1" *ngIf="!user">Создать пользователя</div>
        <div class="body-text-1" *ngIf="user">Редактировать пользователя</div>
        <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Имя*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="name">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Логин*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="login">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Пароль*</mat-label>
                <input type="password" autocomplete="off" matInput formControlName="password">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-selection-list formControlName="profiles">
                <mat-list-option
                    *ngFor="let group of profiles"
                    [checkboxPosition]="'before'"
                    [value]="group.uuid"
                    disableRipple>
                    {{group.name}}
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer">
        <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled button-primary-filled-secondary"
            color="primary"
            [disabled]="form.invalid || form.disabled"
            (click)="save()"
        >
            Сохранить
        </button>
    </div>
</div>

<div class="popup-wrapper" *ngIf="loading">
    <div class="popup-header">
        <div class="body-text-1">
            <skeleton-text [width]="195" [height]="18" [inverseColor]="true"></skeleton-text>
        </div>
    </div>
    <div class="popup-body">
        <div class="popup-body" [formGroup]="form">
            <div class="popup-body-row">
                <skeleton-text [width]="456" [height]="44"></skeleton-text>
            </div>
            <div class="popup-body-row">
                <skeleton-text [width]="456" [height]="44"></skeleton-text>
            </div>
            <div class="popup-body-row">
                <skeleton-text [width]="456" [height]="44"></skeleton-text>
            </div>
            <div class="popup-body-row">
                <div class="loading-options">
                    <div class="loading-option" *ngFor="let i of [1,2,3]">
                        <div class="loading-option-checkbox">
                            <skeleton-text [width]="16" [height]="16"></skeleton-text>
                        </div>
                        <div class="loading-option-text">
                            <skeleton-text [width]="150" [height]="18"></skeleton-text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <skeleton-text [width]="200" [height]="36" [radius]="8"></skeleton-text>
    </div>
</div>

