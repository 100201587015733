import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Company} from '../../shared/types/companies';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';

export enum CuCompanyPopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

@Component({
    templateUrl: './cu-company-popup.component.html',
    styleUrls: ['./cu-company-popup.component.scss']
})
export class CuCompanyPopupComponent {
    public form: FormGroup;

    public popupResult = CuCompanyPopupResult;

    public error: string | null = null;

    constructor(
        private readonly dialogRef: MatDialogRef<CuCompanyPopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly company: Company
    ) {
        this.form = this.fb.group({
            uuid: [null],
            name: ['', [Validators.required]],
            inn: [''],
            address: [''],
            phone: [''],
            email: [''],
            contact: [''],
            note: [''],
        });

        if (this.company) {
            this.form.patchValue({
                uuid: company.uuid,
                name: company.name,
                inn: company.inn,
                address: company.address,
                phone: company.phone,
                email: company.email,
                contact: company.contact,
                note: company.note,
            });
        }
    }

    public close(result: CuCompanyPopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public save(): void {
        this.processSave(CuCompanyPopupResult.SAVE);
    }

    public saveAndCreateContract(): void {
        this.processSave(CuCompanyPopupResult.SAVE_AND_CREATE);
    }

    private processSave(result: CuCompanyPopupResult): void {
        const model = this.form.value as Company;

        this.error = null;
        this.form.disable();

        const request$ = model.uuid ?
            this.httpClient.put<Company>('/adminpanelapi/company/update', model)
            : this.httpClient.post<Company>('/adminpanelapi/company/create', model);

        request$
            .pipe(
                tap(company => {
                    this.close(result, {companyUuid: company.uuid});
                }),
                catchError(error => {
                    this.error = error.error.message;
                    this.form.enable();
                    return of(error);
                }),
            )
            .subscribe();
    }
}
