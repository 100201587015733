<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1" *ngIf="!data.limit">Создать ограничение</div>
        <div class="body-text-1" *ngIf="data.limit">Редактировать ограничение</div>
        <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div class="popup-body-row">
            <mat-selection-list formControlName="clientGroupUuid">
                <mat-list-option
                    *ngFor="let group of data.clientGroups"
                    [checkboxPosition]="'before'"
                    [value]="group.uuid"
                    disableRipple>
                    {{group.name}}
                </mat-list-option>
            </mat-selection-list>
        </div>
        <div class="popup-body-row">
            <input-date-time-range-picker [placeholder]="'Период действия'" [start]="form.controls.start.value" [end]="form.controls.end.value"
                                          (rangeChange)="onRangeChange($event)"></input-date-time-range-picker>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer">
        <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled button-primary-filled-secondary"
            color="primary"
            [disabled]="form.invalid || form.disabled"
            (click)="save()"
        >
            Сохранить
        </button>
    </div>
</div>

