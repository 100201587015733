import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {EventType} from '../../../shared/types/events';
import {BaseCatalog} from '../base-catalog/base-catalog';

@Component({
    selector: 'app-subscription-type-catalog',
    templateUrl: './subscription-type-catalog.component.html',
    styleUrls: ['./subscription-type-catalog.component.scss']
})
export class SubscriptionTypeCatalogComponent implements BaseCatalog<EventType> {

    public form: FormGroup;
    public waiting = false;

    @Output()
    create: EventEmitter<EventType> = new EventEmitter<EventType>();

    @Output()
    close: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient
    ) {
        this.form = this.fb.group({
            name: [null, Validators.required]
        });
    }

    public async add(): Promise<void> {
        this.form.disable();
        this.waiting = true;
        try {
            const model = {
                consistType: 'SEASON_TICKET',
                name: this.form.value.name
            };
            const eventType = await this.httpClient.post<EventType>(`/adminpanelapi/event-type/create`, model).toPromise();
            this.create.emit(eventType);
            this.close.emit();
            this.form.enable();
        } catch (e) {
            this.form.enable();
            this.form.controls.name.setErrors({invalid: e.error.message});
        }
        this.waiting = false;
    }
}
