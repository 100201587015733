<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1" *ngIf="!company">Создать клиента</div>
        <div class="body-text-1" *ngIf="company">Редактировать клиента</div>
        <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Название*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="name">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>ИНН</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="inn">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Адрес</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="address">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Телефон</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="phone">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Почта</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="email">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Контактное лицо</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="contact">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Примечание</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="note">
            </mat-form-field>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer">
        <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled button-primary-filled-secondary"
            color="primary"
            [disabled]="form.invalid || form.disabled"
            (click)="save()"
        >
            Сохранить
        </button>
        <button
            mat-stroked-button
            disableRipple
            type="button"
            class="button-primary-outline"
            color="primary"
            [disabled]="form.invalid || form.disabled"
            (click)="saveAndCreateContract()"
        >
            Сохранить и добавить договор
        </button>
    </div>
</div>

