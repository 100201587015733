<div class="popup-wrapper">
    <div class="popup-header">
        <div class="body-text-1">Создать клиента</div>
        <button
            mat-icon-button
            disableRipple
            type="button"
            class="button-icon"
            color="primary"
            (click)="close(popupResult.CANCEL)"
        >
            <mat-icon svgIcon="times"></mat-icon>
        </button>
    </div>
    <div class="popup-body" [formGroup]="form">
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Имя</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="firstName">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Фамилия*</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="lastName">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Отчество</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="middleName">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Email</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="email">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Телефон</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="phone">
            </mat-form-field>
        </div>
        <div class="popup-body-row">
            <input-date-time-picker placeholder="День рождения" [disabled]="form.disabled" [date]="form.value.birthday" [showTime]="false"
                                    (change)="onBirthdayChange($event)"></input-date-time-picker>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Адрес</mat-label>
                <input type="text" autocomplete="off" matInput formControlName="address">
            </mat-form-field>
        </div>
        <div class="popup-body-row sex-row">
            <label color="basic" [hideIndicator]="true" placeholder="Пол"></label>
            <mat-radio-group formControlName="sex">
                <mat-radio-button [value]="Sex.MALE">Мужской</mat-radio-button>
                <mat-radio-button [value]="Sex.FEMALE">Женский</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="popup-body-row">
            <mat-form-field class="input-field" color="primary">
                <mat-label>Пароль</mat-label>
                <input type="password" autocomplete="off" matInput formControlName="password">
            </mat-form-field>
        </div>
    </div>
    <div class="error-container" *ngIf="error">
        <inline-alert-component [text]="error"></inline-alert-component>
    </div>
    <div class="popup-footer">
        <button
            mat-raised-button
            disableRipple
            type="button"
            class="button-primary-filled button-primary-filled-secondary"
            color="primary"
            [disabled]="form.invalid || form.disabled"
            (click)="save()"
        >
            Создать
        </button>
    </div>
</div>

