import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Event, EventType, Team} from '../../shared/types/events';
import {HttpClient} from '@angular/common/http';
import {EventTypeCatalogComponent} from '../../components/reference-book/event-type-catalog/event-type-catalog.component';
import {TeamCatalogComponent} from '../../components/reference-book/team-catalog/team-catalog.component';
import { UiKitModule } from 'src/shared/ui-kit/ui-kit.module';

export enum CuBlankStorePopupResult {
    CANCEL,
    SAVE,
    SAVE_AND_CREATE
}

@Component({
    selector: 'app-cu-blank-store-popup',
    templateUrl: './cu-blank-store-popup.component.html',
    styleUrls: ['./cu-blank-store-popup.component.scss']
})
export class CuBlankStorePopupComponent implements OnInit {

    storeOpened = false;

    form: FormGroup;

    public loading = true;
    public waiting = false;
    public internalStorehouses: any[] = [];

    public error: string | null = null;

    popupResult = CuBlankStorePopupResult;

    constructor(
        private readonly dialogRef: MatDialogRef<CuBlankStorePopupComponent>,
        private readonly fb: FormBuilder,
        private readonly httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public readonly blankStores: any
    ) {
        this.form = this.fb.group({
            uuid: [null],
            serial: [null, Validators.required],
            numberStart: [null, Validators.required],
            numberEnd: [null, Validators.required],
        });
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await Promise.all([
            // this.loadEventTypes(),
            // this.loadTeams()
        ]);
        if (this.blankStores.storehouses.length) {
            this.blankStores.storehouses.forEach((item: any) => {
                if (item.storehouseType === 'CASHIER') {
                    this.internalStorehouses.push(item)
                }
            });
        }
        if (this.blankStores?.store) {
            this.form.patchValue({
                uuid: this.blankStores.store?.storeUuid,
                serial: this.blankStores.store?.blanks?.serial,
                numberStart: this.blankStores.store?.blanks?.numberStart,
                numberEnd: this.blankStores.store?.blanks?.numberEnd,
            });
        }
        this.loading = false;
    }

    public close(result: CuBlankStorePopupResult, data?: any): void {
        this.dialogRef.close({
            result,
            data
        });
    }

    public async save(action?: string): Promise<void> {
        try {
            await this._save(action);
            // const model = {
            //     action: this.blankStores.action,
            //     cashierUuid: this.form.value.uuid
            // }
            this.close(CuBlankStorePopupResult.SAVE);
        } catch (e) {

        }
    }

    public async _save(action?: string): Promise<any> {
        let model: any = [{
            serial: this.form.value.serial,
            numberStart: this.form.value.numberStart,
            numberEnd: this.form.value.numberEnd,
        }];
        this.waiting = true;
        this.error = null;
        this.form.disable();
        if (action) {
            this.blankStores.action = action;
        }
        try {
            if (this.blankStores.action === 'add') {
                return await this.httpClient.post(`/adminpanelapi/ticket-control-form/supply`, model).toPromise();
            } else if (this.blankStores.action === 'writeOff') {
                return await this.httpClient.put(`/adminpanelapi/ticket-control-form/write-off`, model).toPromise();
            } else if (this.blankStores.action === 'issue') {
                model = {
                    cashierUuid: this.form.value.uuid,
                    moveModels: model
                };
                return await this.httpClient.put(`/adminpanelapi/ticket-control-form/issue`, model).toPromise();
            } else if (this.blankStores.action === 'receive') {
                model = {
                    cashierUuid: this.form.value.uuid,
                    moveModels: model
                };
                return await this.httpClient.put(`/adminpanelapi/ticket-control-form/receive`, model).toPromise();
            }
            
        } catch (e) {
            //@ts-ignore
            this.error = e.error.message;
            this.form.enable();
            this.waiting = false;
            throw e;
        }
    }

    public async saveAndCreatEventSession(): Promise<void> {
        try {
            const event = await this._save();
            this.close(CuBlankStorePopupResult.SAVE_AND_CREATE, event);
        } catch (e) {

        }
    }

    public getStorehouses() {
        return this.internalStorehouses;
    }

}
